import logo from './assets/westios_logo.png';

function App() {
  return (
    <div style={{margin: "5rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <img src={logo} alt="westios logo" width="200px"/>
      {/* <div style={{textTransform: "uppercase", fontSize: ".9rem", marginTop: "1rem"}}>Coming soon</div> */}
    </div>
  );
}

export default App;
